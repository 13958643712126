var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "col d-flex justify-content-end align-items-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary mt-4 mr-3",
    attrs: {
      "data-cy": "new-asset",
      "to": {
        name: 'automation-create'
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus fa-fw"
  }), _vm._v(" New Automation")])], 1), _c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.automations,
      "total": _vm.automationsTotal,
      "loading": _vm.loading || _vm.automationsLoading.list,
      "columns": _vm.columns,
      "filters": _vm.filters,
      "page-size": 20,
      "fetch-items": _vm.refreshAutomations,
      "other-params": _vm.otherParams,
      "default-sort-key": "uploadedAt",
      "default-sort-direction": -1
    },
    scopedSlots: _vm._u([{
      key: "lastCollection",
      fn: function (_ref) {
        var _data$lastCollection;
        var data = _ref.data;
        return [_vm._v(" " + _vm._s(_vm._f("date")((_data$lastCollection = data.lastCollection) === null || _data$lastCollection === void 0 ? void 0 : _data$lastCollection.collectedAt, 'Do MMM YYYY HH:mm:ss')) + " ")];
      }
    }, {
      key: "mpan",
      fn: function (_ref2) {
        var _vm$accounts$find;
        var data = _ref2.data;
        return [data.category === 'accounts' ? _c('div', [_vm._v(_vm._s((_vm$accounts$find = _vm.accounts.find(function (a) {
          return a.automationIds.includes(data._id);
        })) === null || _vm$accounts$find === void 0 ? void 0 : _vm$accounts$find.meterPointNumber))]) : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function (_ref3) {
        var data = _ref3.data;
        return [_c('div', [_c('router-link', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "to": {
              name: 'automation-manage',
              params: {
                id: data._id
              }
            }
          }
        }, [_vm._v("Manage")])], 1)];
      }
    }])
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }